@media (max-width: 540px){
    .nav-ind {
        font-size: 15px !important;
    }
    .logo-image img{
        width: 200px;
    }
}

.logo-image{
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo {
    height: 70px; 
    margin-bottom: 30px;
}
.nav-bar{
    display: flex;
    align-items: center;
    justify-content: center;
}

nav{
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 10vh;
    border-radius: 1em;
    color:white;
}

.nav-links{
    border: 1px double #ffffff;
    padding-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-ind{
    margin: 10px 30px;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #ffffff;
    list-style: none;
}

