footer{
	display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
	color: #9BA4A6;
	text-align: center;
	padding-top: 100px;
}

.container-footer {
    font-family: 'Roboto', sans-serif;
    max-width: 1280px;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
}
.container-footer p{
	margin-bottom: 0;
}
.container-footer .btn-dark{
	background: #343a40;
    box-shadow: none;
    color: #fff;
    border-color: #343a40;
    font-size: 13px;
    padding: 3px 5px;
}