.dashboard{
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
}

.card-doc{
    border: 2px solid #cccccc !important;
    border-radius: 1px !important;
    font-family: 'Roboto', sans-serif;
    padding: 0.5em;
    min-height: 70vh;
    max-width: 1280px;
    width: 90%; 
    display: flex;
    flex-direction: column;

}

@media (max-width: 850px) {
    .card-doc{
        width: 95%; 
        height: 75vh;
        /* flex-direction: column; */
    }
    .docButtons{
        width: 50% !important;
    }
}

.docHeadingDashboard{
    flex: 1;
    padding-top: 1.5em;
    padding-left: 1.5em;
    border: 1px solid #000;
    border-bottom-width: 0px;
    flex-wrap: wrap;
}


.docNumber{
    flex: 1;
    flex-direction: column;
    align-items: center;
    display: flex;
    border: 1px solid #000;
    border-top-width: 0px;
    justify-content: center;
    /* padding: 2em; */
    padding-bottom: 1.5em;
    /* padding-top: 1.5em; */
}

.docDetails{
    flex: 8;
    padding: 1.25em;
    padding-bottom: 0em;
    border: 1px solid #000;
    margin-top: 0.5em;
    /* margin-bottom: 0.5em; */
    border-bottom: 0px;
}

.placeholder{
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardMini{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.tokenID{
    font-size: 2em;
}
.tokenHash{
    font-size: 0.75em;
    margin-bottom: 1.5em;
}

.tokenID p{
    margin-bottom: 0px;
}

.inviteeSignee{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1.25em;
}

.tableHeading{
    font-size: 1.35em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.indAddress{
    font-size: 0.9em;
}

.inviteesTable{
    flex: 1;
    border-right: 0.5px solid #000;
}

.tableRows{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.signeesTable{
    flex: 1;
}



.buttonAreaDashboard{
    flex: 1;
    /* display: inline-block; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #000;
    border-top: 0px;
    padding: 0em 1em 1em 1em;
    justify-content: flex-end;
}

.inputSubmit{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.inputSubmit > Input {
    width: 70%center;
}

.docButtons{
    width: 20%;
}

.addressLabel {
    margin-top: 0.5em;
    margin-left: 5%;
    font-size: 1em;
}